<script lang="ts" setup>
const { t } = useT();

defineProps<{
	isDepositStreak?: boolean;
	isSeason?: boolean;
}>();
</script>
<template>
	<template v-if="isDepositStreak">
		<NuxtImg
			src="/nuxt-img/deposit-streak/ticket.png"
			class="streak"
			format="avif"
			loading="lazy"
			alt="ticket"
			width="48"
			height="48"
		/>
		<span>{{ t("Prize Machine Pass") }}</span>
	</template>
	<template v-else-if="isSeason">
		<NuxtIcon name="20/ticket" filled />
		<span class="desk">+ {{ t("Get your Free Hawaii Adventure Pass") }}</span>
		<span class="mob">+ {{ t("Free Hawaii Pass") }}</span>
	</template>
</template>

<style lang="scss" scoped>
.streak {
	width: 30px;
	height: 30px;
}

.desk {
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.mob {
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.nuxt-icon {
	font-size: 30px;

	@include media-breakpoint-down(md) {
		font-size: 20px;
	}
}
</style>
